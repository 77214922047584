<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="BItip">{{ $t("briefIntroduction.BI_tip") }}</div>
      <div v-html="institute" class="institute"></div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们-简介
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 17:23
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
        {
          title_zh: "简介",
          title_en: "brief introduction",
          path: "",
        },
      ],
      institute: "",
    };
  },
  mounted() {
    this.getInstitute()
  },
  methods: {
    /**
     * 首页数据
     * 刘嘉鑫
     * 2022-8-16
     */
    getInstitute() {
      this.$request({
        url: "/Index/index",
        data: {},
      }).then((res) => {
        console.log("简介", res);
        res.introduce = res.introduce.replace(
          new RegExp("<img", "g"),
           "<img style='max-width:100%;height:auto;margin:0 auto;'"
        );
        this.institute = res.introduce;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 60px 310px;
  background: #1a2a60;
}
.BItip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 78px;
}

.institute{
  color: #ffffff;
}
</style>